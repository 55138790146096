<template>
  <v-card rounded="lg" min-height="268">
    <v-list v-for="(skills, category) in groupedSkills" :key="category" >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title" v-text="$t(`label['${category.toLowerCase()}']`)"/>
        </v-list-item-content>
      </v-list-item>

      <v-list-item-group color="primary" active-class="none">
        <v-list-item v-for="(skill, key) in skills" :key="key">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content v-bind="attrs" v-on="on">
                <v-list-item-title v-text="skill.name"/>
              </v-list-item-content>
              <v-rating
                  dense
                  small
                  readonly
                  background-color="grey darken-1"
                  color="light-blue lighten-2"
                  empty-icon="mdi-shield-outline"
                  full-icon="mdi-shield-check"
                  :value="parseInt(skill.rank)"
              />
            </template>
            <v-icon left small dark>
              mdi-{{ skill.name.toLowerCase().replace('.', '').replace(/[0-9]/m, '') }}
              mdi-language-{{ skill.name.toLowerCase().replace('.', '') }}
            </v-icon>
            <span v-text="$tc('label.skills_rank', skill.rank, {n: skill.rank})"/>
          </v-tooltip>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  data: () => ({}),
  computed: {
    groupedSkills(){
      return this.$store.getters['skills/list'] || {}
    },
  },
  mounted() {
    this.listSkills();
  },
  methods: {
    /**
     * Load the skills
     * @returns {Promise<boolean>}
     */
    listSkills() {
      return this.$store.dispatch('skills/list');
    }
  }
}
</script>